<template>
  <div class="goods-list">
    <h4>多多兔-商品列表</h4>
    <el-table :data="tableData" style="width: 100%" v-loading="loading" element-loading-spinner="el-icon-loading">
      <el-table-column prop="buy_time" width="200" label="入库时间">
      </el-table-column>
      <el-table-column prop="categorize" label="类型">
      </el-table-column>
      <el-table-column prop="original_price" label="原格">
      </el-table-column>
      <el-table-column prop="price" label="现价">
      </el-table-column>
      <el-table-column prop="fineness" label="成色">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">查看详情</el-button>
          <el-button @click="editClick(scope.row)" type="text" size="small">修改信息</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-size="10" layout="prev, pager, next" :total="total" @current-change='chageSize'>
    </el-pagination>
  </div>
</template>


<script>
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      total: 0
    }
  },
  created() {
    this.getgoods()
  },
  methods: {
    handleClick() { },
    editClick() { },
    chageSize(value) { },
    async getgoods() {
      const res = await this.axios.get(`cashier/good`)
      if (res.code === 200) {
        this.tableData = res.data.list
        this.total = res.data.meta.total
        console.log(res)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.goods-list {
  padding-top: 15px;
}
h4 {
  font-size: 30px;
  margin-bottom: 10px;
}
.el-pagination {
  float: right;
}
</style>